import React from "react";
import { DiscussionEmbed } from "disqus-react";
import styled from "styled-components";
import UIArticle from "jecko-components/sections/UIArticle";
import UIContent from "jecko-components/sections/UIContent";
import UIHeader from "jecko-components/sections/UIHeader";
import UIFooter from "jecko-components/sections/UIFooter";
import Link from "./Link";

const Categories = ({ categories }) => {
  if (!categories || !categories.length) {
    return null;
  }

  return (
    <span>
      |{" "}
      {categories
        .map(category => category.toLowerCase())
        .map((category, index) => {
          return (
            <Link key={index} to={`/blog/category/${category}`}>
              {category}
            </Link>
          );
        })}
    </span>
  );
};

const SubHeader = styled.span`
  filter: brightness(75%);
`;

export default function BlogArticle({
  url,
  title,
  bodyHtml,
  date,
  showReadMore,
  categories,
  blogPost = true,
  fullArticle = false
}) {
  return (
    <UIArticle>
      <UIHeader>
        <h2>
          <Link to={url}>{title}</Link>
        </h2>
        <SubHeader>
          {date} <Categories categories={categories} />
        </SubHeader>
      </UIHeader>
      <UIContent>
        <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
      </UIContent>
      {showReadMore && (
        <UIFooter>
          <Link to={url}>Read More</Link>
        </UIFooter>
      )}
      {fullArticle && blogPost && (
        <DiscussionEmbed
          shortname="jacobx1"
          config={{
            identifier: url,
            title,
            url: `https://jacobx1.com${url}`
          }}
        />
      )}
    </UIArticle>
  );
}
