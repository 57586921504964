var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import UIHeader from "./UIHeader";
import UIContent from "./UIContent";
import UILink from "../buttons/UILink";
var UIArticleBase = styled.article(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem 0rem;\n\n  border-radius: 12px;\n\n  & + & {\n    margin-top: 1rem;\n  }\n\n  ", " {\n    padding-bottom: 0.5rem;\n  }\n\n  ", " h2 {\n    font-size: 1.5rem;\n    font-weight: 600;\n    margin: 0 0 0.5rem 0;\n  }\n\n  ", " ", " {\n    filter: none;\n  }\n\n  ", " {\n    margin-top: 1rem;\n  }\n\n  img {\n    max-width: 100%;\n  }\n"], ["\n  padding: 1rem 0rem;\n\n  border-radius: 12px;\n\n  & + & {\n    margin-top: 1rem;\n  }\n\n  ", " {\n    padding-bottom: 0.5rem;\n  }\n\n  ", " h2 {\n    font-size: 1.5rem;\n    font-weight: 600;\n    margin: 0 0 0.5rem 0;\n  }\n\n  ", " ", " {\n    filter: none;\n  }\n\n  ", " {\n    margin-top: 1rem;\n  }\n\n  img {\n    max-width: 100%;\n  }\n"])), UIHeader, UIHeader, UIHeader, UILink, UIContent);
export default UIArticleBase;
var templateObject_1;
