import React from "react";

import UIApplication from "jecko-components/layouts/UIApplication";
import UISiteTitle from "jecko-components/headings/UISiteTitle";
import UIAppBar from "jecko-components/layouts/UIAppBar";
import UINav from "jecko-components/nav/UINav";
import UIGrowSpacer from "jecko-components/layouts/UIGrowSpacer";
import UIAppContent from "jecko-components/layouts/UIAppContent";
import UIAppBody from "jecko-components/layouts/UIAppBody";
import UIAppFooter from "jecko-components/layouts/UIAppFooter";
import Octicon, { MarkGithub } from "@primer/octicons-react";
import LinkedInIcon from "../img/iconmonstr-linkedin-4.svg";
import UILink from "jecko-components/buttons/UILink";
import UINavImageItem from "jecko-components/nav/UINavImageItem";

import { Helmet } from "react-helmet";
import Link from "./Link";
import NavItem from "./NavItem";
import styled from "styled-components";

const BlogFooter = styled(UIAppFooter)`
  text-align: center;
  filter: brightness(0.75);
`;

export default function AppBase({ subPage, children }) {
  return (
    <UIApplication>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"Jake's Blog"}
          {subPage ? ` - ${subPage}` : ""}
        </title>
        <link rel="canonical" href="https://jacobx1.com" />
        <meta
          name="description"
          content="Jake's blog focusing on tech, leadership, and management."
        />
        <html lang="en" />
      </Helmet>
      <UIAppBar>
        <UISiteTitle>
          <Link to="/">Jake Schwind</Link>
        </UISiteTitle>
        <UIGrowSpacer />
        <UINav>
          <NavItem to="/">Home</NavItem>
          <NavItem to="/blog/categories">Tags</NavItem>
          <NavItem to="/about">About Me</NavItem>
          <UINavImageItem
            href="https://www.linkedin.com/in/jschwin/"
            target="_blank"
            title="LinkedIn"
          >
            <LinkedInIcon style={{ fill: "white", width: "20px" }} />
          </UINavImageItem>
          <UINavImageItem
            href="https://github.com/jacobx1"
            target="_blank"
            title="GitHub"
          >
            <Octicon icon={MarkGithub} verticalAlign="top" size={20} />
          </UINavImageItem>
        </UINav>
      </UIAppBar>
      <UIAppContent>
        <UIAppBody>
          {subPage && <h1>{subPage}</h1>}
          {children}
        </UIAppBody>
      </UIAppContent>
      <BlogFooter>
        Jacob Schwind&apos;s personal blog &copy; {new Date().getFullYear()}.
        Powered by{" "}
        <UILink href="https://gatsbyjs.org" external={true}>
          Gatsby
        </UILink>{" "}
        and{" "}
        <UILink href="https://www.netlify.com/" external={true}>
          Netlify
        </UILink>
        .
      </BlogFooter>
    </UIApplication>
  );
}
