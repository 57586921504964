var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from "styled-components";
var UILink = styled.a.attrs(function (props) {
    var newProps = __assign({}, props);
    if (props.external) {
        newProps.target = "_blank";
        newProps.rel = "noreferrer";
    }
    return newProps;
})(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export default UILink;
var templateObject_1;
