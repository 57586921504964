var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import UINavItem from "./UINavItem";
import navContext from "../context/navContext";
var UINav = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 1em;\n  margin-left: 1em;\n  overflow-x: hidden;\n  flex-direction: row;\n  font-family: \"IBM Plex Sans\";\n"], ["\n  display: flex;\n  padding: 1em;\n  margin-left: 1em;\n  overflow-x: hidden;\n  flex-direction: row;\n  font-family: \"IBM Plex Sans\";\n"])));
var UIMoreSurface = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 999;\n  width: 0px;\n  ::before {\n    width: 0;\n    content: \"#\";\n    opacity: 0;\n  }\n"], ["\n  position: absolute;\n  z-index: 999;\n  width: 0px;\n  ::before {\n    width: 0;\n    content: \"#\";\n    opacity: 0;\n  }\n"])));
var UIMoreContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  top: 1em;\n  min-width: 100px;\n  left: -100px;\n  padding: 0.5rem;\n  background-color: rgba(0, 0, 0, 1);\n  border: 1px solid rgba(255, 255, 255, 0.5);\n  & > * {\n    margin: 0;\n    display: block;\n  }\n  & > * + * {\n    margin-left: 0;\n    margin-top: 0.25rem;\n  }\n"], ["\n  position: relative;\n  top: 1em;\n  min-width: 100px;\n  left: -100px;\n  padding: 0.5rem;\n  background-color: rgba(0, 0, 0, 1);\n  border: 1px solid rgba(255, 255, 255, 0.5);\n  & > * {\n    margin: 0;\n    display: block;\n  }\n  & > * + * {\n    margin-left: 0;\n    margin-top: 0.25rem;\n  }\n"])));
var MoreButton = function (_a) {
    var children = _a.children;
    var _b = useState(false), moreOpen = _b[0], setMoreOpen = _b[1];
    var toggleMoreOpen = function () {
        setMoreOpen(!moreOpen);
    };
    var ref = useRef();
    var handleClick = function (e) {
        if (!ref.current || ref.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setMoreOpen(false);
    };
    useEffect(function () {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return function () {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(UINavItem, { style: { position: "relative" }, onClick: toggleMoreOpen }, "More"),
        moreOpen && (React.createElement("div", { style: { width: 0, height: 0, margin: 0 } },
            React.createElement(UIMoreSurface, null,
                React.createElement(UIMoreContent, { ref: ref },
                    React.createElement(navContext.Provider, { value: { collapsed: true } }, children)))))));
};
export default (function (_a) {
    var children = _a.children;
    var ref = useRef();
    var _b = useState(children), mainChildren = _b[0], setMainChildren = _b[1];
    var _c = useState([]), otherChildren = _c[0], setOtherChildren = _c[1];
    var _d = useState(0), menuWidth = _d[0], setMenuWidth = _d[1];
    var _e = useState(false), grew = _e[0], setGrew = _e[1];
    useEffect(function () {
        setMainChildren(children);
        setOtherChildren([]);
    }, [children]);
    var getMenuWidth = function () { return ref.current.clientWidth; };
    var getMenuScrollWidth = function () { return ref.current.scrollWidth; };
    useEffect(function () {
        if (grew) {
            setMainChildren(children);
            setOtherChildren([]);
            setGrew(false);
            return;
        }
        if (getMenuWidth() !== getMenuScrollWidth() && mainChildren.length > 0) {
            setOtherChildren(__spreadArrays([
                mainChildren[mainChildren.length - 1]
            ], otherChildren));
            setMainChildren(mainChildren.slice(0, mainChildren.length - 1));
        }
    }, [menuWidth, mainChildren, otherChildren, grew]);
    useEffect(function () {
        var onResize = function () {
            setMenuWidth(getMenuWidth());
            setGrew(getMenuWidth() > menuWidth);
        };
        window.addEventListener("resize", onResize);
        return function () { return window.removeEventListener("resize", onResize); };
    }, []);
    return (React.createElement(UINav, { ref: ref },
        mainChildren,
        otherChildren.length > 0 && React.createElement(MoreButton, null, otherChildren)));
});
var templateObject_1, templateObject_2, templateObject_3;
