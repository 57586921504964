var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import "normalize.css";
import { createGlobalStyle } from "styled-components";
import { standardTheme } from "../theme/theme";
import appContext from "../context/appContext";
var UILayoutBase = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: auto;\n"], ["\n  margin: auto;\n"])));
var GlobalStyle = createGlobalStyle(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono|IBM+Plex+Sans:400&display=swap');\n\n  body {\n    font-family: Arial, Helvetica, sans-serif;\n    font-weight: 400;\n    background-color: ", ";\n    color: ", ";\n    overflow-x: hidden;\n  }\n\n  h1, h2, h3, h4, h5, h6, h7 {\n    font-family: Arial, Helvetica, sans-serif;\n    font-weight: 700;\n  }\n\n  code {\n    font-family: 'IBM Plex Mono';\n  }\n\n  a {\n    text-decoration: none;\n    cursor: pointer;\n    color: orange;\n\n    :hover {\n      filter: brightness(75%);\n      text-decoration: underline;\n    }\n  }\n"], ["\n  @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono|IBM+Plex+Sans:400&display=swap');\n\n  body {\n    font-family: Arial, Helvetica, sans-serif;\n    font-weight: 400;\n    background-color: ", ";\n    color: ", ";\n    overflow-x: hidden;\n  }\n\n  h1, h2, h3, h4, h5, h6, h7 {\n    font-family: Arial, Helvetica, sans-serif;\n    font-weight: 700;\n  }\n\n  code {\n    font-family: 'IBM Plex Mono';\n  }\n\n  a {\n    text-decoration: none;\n    cursor: pointer;\n    color: orange;\n\n    :hover {\n      filter: brightness(75%);\n      text-decoration: underline;\n    }\n  }\n"])), function (props) { return props.theme.backgroundColor; }, function (props) { return props.theme.textColor; });
export default function UIApplication(_a) {
    var children = _a.children;
    var _b = useState("default"), currentTheme = _b[0], setCurrentTheme = _b[1];
    var _c = useState(0), layerIndex = _c[0], setLayerIndex = _c[1];
    var addLayer = function () {
        var newIndex = layerIndex + 1;
        setLayerIndex(newIndex);
        return newIndex;
    };
    var removeLayer = function () {
        setLayerIndex(layerIndex - 1);
    };
    return (React.createElement(appContext.Provider, { value: {
            currentTheme: currentTheme,
            setCurrentTheme: setCurrentTheme,
            addLayer: addLayer,
            removeLayer: removeLayer
        } },
        React.createElement(ThemeProvider, { theme: standardTheme },
            React.createElement(React.Fragment, null,
                React.createElement(GlobalStyle, null),
                React.createElement(UILayoutBase, null, children)))));
}
var templateObject_1, templateObject_2;
